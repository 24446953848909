import React, { Component, createRef } from "react";
import { inverseScale } from "../scale.js";
import { updateArticleView } from "../actions/articleView";
import { connect } from "react-redux";

let positions = {
  left: "96px",
  center: "114px",
  right: "132px",
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const getNewsIdFromArticleUrl = (url) => {
  const match = url.match(/\/news\/([^/]+)\/article/);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};

const isArticleDetailPage = (pathname) => {
  return pathname.includes("/article") && pathname.includes("/news/");
};

class BackNextSwitch extends Component {
  constructor(props, context) {
    super(props, context);

    this.elementRef = createRef();
    this.switchRef = createRef();
    this.click = this.click.bind(this);
  }

  forceReloadToNews(newsId) {
    const newsUrl = `/news/${newsId}`;
    console.log(`SOLUCIÓN DEFINITIVA: Recargando página a ${newsUrl}`);

    window.location.href = `${newsUrl}?t=${Date.now()}`;
  }

  toggle(key, then) {
    if (this._timeout) return;

    this.switchRef.current.style.left = positions[key];
    this._timeout = setTimeout(() => {
      this.switchRef.current.style.left = positions.center;
      this._timeout = null;

      if (
        then === "back" &&
        isSafari() &&
        isArticleDetailPage(window.location.pathname)
      ) {
        const newsId = getNewsIdFromArticleUrl(window.location.pathname);
        this.forceReloadToNews(newsId);
      }
      else {
        if (this.props.isArticleView) {
          this.props.updateArticleView(false);
        }
        window.history[then]();
      }
    }, 200);
  }

  click(e) {
    let width = 272,
      half = width / 2;
    let left = e.pageX * inverseScale() - this.elementRef.current.offsetLeft;

    if (left < half) this.toggle("left", "back");
    else this.toggle("right", "forward");
  }

  render() {
    return (
      <div
        className="back-next-switch"
        ref={this.elementRef}
        onClick={this.click}
      >
        <div className="back-next-switch-wrapper">
          <div className="background" />
          <div className="back" />
          <div className="next" />
          <div className="switch" ref={this.switchRef} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    isArticleView: state.articleView,
  };
};

export default connect(mapStateToProps, { updateArticleView })(BackNextSwitch);
