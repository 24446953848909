import preloadImages from '../util/preload-images';
import React, {Component} from 'react';
import frame1 from '../../images/loading-indicator/frame_01.webp';
import frame2 from '../../images/loading-indicator/frame_02.webp';
import frame3 from '../../images/loading-indicator/frame_03.webp';
import frame4 from '../../images/loading-indicator/frame_04.webp';
import frame5 from '../../images/loading-indicator/frame_05.webp';
import frame6 from '../../images/loading-indicator/frame_06.webp';
import frame7 from '../../images/loading-indicator/frame_07.webp';
import frame8 from '../../images/loading-indicator/frame_08.webp';
import frame9 from '../../images/loading-indicator/frame_09.webp';
import frame10 from '../../images/loading-indicator/frame_10.webp';

function cycle(array){
    let i = -1
    return ()=>{
        i++; if (i >= array.length) i = 0
        return array[i]
    }
}

const images = [
    frame1,
    frame2,
    frame3,
    frame4,
    frame5,
    frame6,
    frame7,
    frame8,
    frame9,
    frame10,
];

let imagesPromise = preloadImages(images)
let imagesLoaded  = true;

const frames = images.map(path=>`url('${path}')`)

const next_frame = cycle(frames)

export default class LoadingIndicator extends Component {
    constructor(props){
        super(props)
        this.state = {frame:next_frame(), visible: false, imagesLoaded}
    }
    componentDidMount(){
        let wait = 100
        this.timeout = setTimeout(()=>{
            this.startAnimating()
        }, wait)
        if (!this.state.imagesLoaded) {
            imagesPromise.then(()=>{
                if (this.unmounted) return
                this.setState({imagesLoaded})
            })
        }
    }
    startAnimating(){
        let frameLength = 1000 / 15
        this.interval = setInterval(()=>{
            this.setState({frame:next_frame(), visible: true})
        }, frameLength)
    }
    componentWillUnmount(){
        this.unmounted = true
        clearTimeout(this.timeout)
        clearInterval(this.interval)
    }
    render(){
        let {frame, visible, imagesLoaded} = this.state
        const { isEmbed } = this.props

        const style = {
            style: '166px', height:'168px',
            backgroundSize : 'contain',
            backgroundRepeat : 'no-repeat',
            backgroundPosition : 'center'
        };

        if(isEmbed) {
            style.style = '85px'
            style.height = '85px'

        }
        if (visible && imagesLoaded) {
            style.backgroundImage = frame;
        }

        return (<div className="loading-indicator" style={style}> </div>)
    }
}
