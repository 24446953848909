import React, {Component} from 'react';
import cx from 'classnames'

export default class More extends Component {
    render(){
        let {assets, onClickItem, thumbnails} = this.props
      
        return (
            <div className="album-more">
              {
                  assets.map((asset, idx)=> (
                    <div
                      className={cx("album-asset", {video: asset.vimeoId})}
                      key={idx}
                      data-id={asset.id}
                      onClick={onClickItem.bind(this, asset)}
                    >
                      <img
                        src={(thumbnails && thumbnails[idx]) || asset.thumb}
                        loading="lazy"
                      />
                    </div>
                  ))
              }
            </div>
        )
    }
}
