import React from 'react'
import Login from '../../services/login'
import WidgetState from './widgetState'
import ScrollBars from "react-scrollbar";

const WidgetInfo = (props) => {
    const {bitrate, playerState, loading, view, toggleView , redirection, isTrack, album, trackId, playTrackInAlbum} = props

    const renderView = () => {

        switch (view ){
            case 'initial':
                return(
                <div className="widget-wrapper">
                    <div className="right-side">
                        <WidgetState bitrate={bitrate} playerState={playerState} loading={loading}/>
                        <p>NYA Download Store Hi Res Audio <br/> Quality whether you want it or not</p>
                    </div>
                    <div className="side-sticker"  onClick={()=>redirection()}></div>
                </div>)
            case 'login' :
                return(
                    <div className="widget-wrapper login">
                        <img className="close-btn" onClick={()=>toggleView('initial')} loading="lazy"/>
                        <div className="right-side">
                            <p>Listen to <br/> the full song</p>
                            <div className="login-btn" onClick={ () =>{
                                toggleView('initial')
                                Login()
                            }}>Log in </div>
                        </div>
                        <div className="side-sticker"  onClick={()=>redirection()}></div>
                    </div>
                )
            case 'lite' :
                return<div className="widget-branding"/>
            default :
             return<div/>
        }
    }

    const renderTrack = () => {
        const { tracks = [] } = album || {};

        const trackList = tracks.map(((_track, index)=>{
            let trackNumber =  index < 9 ? `0${ index + 1}` : index + 1;
            let activeTrack = _track.trackId === trackId ? "track active" : "track";
            if(_track){
                return (<div className= {activeTrack} key ={trackNumber} onClick={()=>{
                    let currentTrack = (_track.trackId === trackId)
                    playTrackInAlbum(currentTrack, _track)
                    // this must play the song in the album just pass down the id.
                }}>
                    <p className="index">{trackNumber}</p>
                    <p>{_track && _track.title}</p>

                </div>)
            }
        })) || []

        return   <div className="tracks-widget"><ScrollBars key="embed" className="embed">{trackList}</ScrollBars></div>
    }

        return (
          <div className={view === "login"? "down-widget login" :"down-widget"}>
            {renderView()}
            {(!isTrack && view == "initial") && (
                 <>{renderTrack()}</>
            )}
          </div>
        );
}


export default WidgetInfo
