import React, {Component} from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import cn                 from 'classnames'

const isAbsoluteURL = new RegExp('^(?:[a-z]+:)?//', 'i');

const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => `<img style="width: 100%;" src="${node.data.target.fields.file.url}" loading="lazy"/>`,
      [INLINES.HYPERLINK]: (node, next) => {
          const target =  isAbsoluteURL.test(node.data.uri) ? '_blank' : ''
          return `<a href="${node.data.uri}" target="${target}" >${next(node.content)}</a>`;
      }
    }
}

export default class Description extends Component {

    render(){
        const { className, simpleHTML, richHTML, hideHeader } = this.props

        return (
            <div className={cn("tab-description", {"tab-description--hideHeader": hideHeader, [className]: className})}>
                {!hideHeader && <div className="header">Description:</div>}
                <div className="content" dangerouslySetInnerHTML={{__html: simpleHTML ? simpleHTML : documentToHtmlString(richHTML, options)}} />
            </div>
        )
    }
}
