import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router'
import _ from 'lodash';

import Separator from './separator';
import Performance from './performance';
import TimelineEventInfo from '../timeline-event-info'
import {
    calcPositionInYear,
    compareDates,
    mergeTourPerformances,
    calcCenterInYears
} from './utils';

const getToursSet = (months) => {
    const allToursSet = new Set();
    months.forEach(month => {
        Array.from(month).forEach(tourByMonth => allToursSet.add(tourByMonth));
    });
    return allToursSet;
}

const TourYear = props => {
    let {year, onClick, selectedEventId, context} = props;
    const [selectedTour, setSelectedTour] = useState(null);
    const months = useSelector(state => Object.values(state.tour.byDate[year] || {}));
    const calcPosition = calcPositionInYear;

    if (!months) return null;
    const allToursSet = getToursSet(months);
    const allTours = Array.from(allToursSet);

    const onTourElementClick = (event, tour) => {
        setSelectedTour(tour);
        onClick(tour._id);
    }

    const toursJSX = allTours.map(tour => {
        const mergedPerformances = mergeTourPerformances(tour)
        if (mergedPerformances.length === 0) {return null;}

        let startedPlacingPerformances = false;
        let finishedPlacingPerformances = false;
        return mergedPerformances.map((mergedPerformance, index, allPerformances) => {
            if (finishedPlacingPerformances) {return null;}

            let performanceStartDate = mergedPerformance.startDate;
            let performanceEndDate = mergedPerformance.endDate;

            const startsThisYear = performanceStartDate.year === year
            const endsThisYear = performanceEndDate.year === year
            const isPartOfThisPage = performanceStartDate.year === year || performanceEndDate.year === year

             // some other page will render this performance
            if (!isPartOfThisPage) {return null;}

            const isNotLast = index < allPerformances.length - 1

            // if we are the first performance of this year but not of the tour we will
            // add an initial separator to connect to the previous year.
            let initialSeparator = null;
            if (index > 0 && !startedPlacingPerformances) {
                const prevPerfEndDate = allPerformances[index - 1].endDate;
                if (prevPerfEndDate.year < year) {
                    initialSeparator = <Separator
                        onClick={onTourElementClick}
                        offset={'0px'}
                        tour={tour}
                        startDate={{year, month:1, day:1}}
                        endDate={performanceStartDate}
                        calcPosition={calcPosition}/>
                }
            }
            let finalSeparator = null;

            if (isNotLast) {
                // if we are the last performance of this year but not of the tour we will
                // change the separator end date to connect to the next year.
                const nextPerfStartDate = allPerformances[index + 1].startDate;
                let separatorEndDate = nextPerfStartDate;
                if (nextPerfStartDate.year > year) {
                    finishedPlacingPerformances = true;
                    separatorEndDate = {year, month: 12, day: 31};
                }

                // if the last performance of this year bleeds into the next year
                // we will skip the final separator
                finalSeparator = (<Separator
                    onClick={onTourElementClick}
                    offset={'0px'}
                    tour={tour}
                    startDate={performanceEndDate}
                    endDate={separatorEndDate}
                    calcPosition={calcPosition}/>);
                if (performanceStartDate.year < performanceEndDate.year && performanceStartDate.year === year || index === allPerformances.length - 1) {
                    finalSeparator = null;
                }

            }
            // if the current performance started didnt start this year
            // but bled into this one we skip the initial separator
            if (!startsThisYear && endsThisYear) {
                initialSeparator = null;
            }

            startedPlacingPerformances = true;
            const stringDate = `${mergedPerformance.startDate.month}.${mergedPerformance.startDate.day}.${mergedPerformance.startDate.year}`;
            return (
                <div key={`tour-${stringDate}-${index}`}>
                    {initialSeparator}
                    <Performance
                        onClick={onTourElementClick}
                        performances={mergedPerformance}
                        year={year}
                        dateElement="year"
                        tour={tour}
                        calcPosition={calcPosition}
                        iconDimensions={{width: 28, height: 28}}
                    />
                    {finalSeparator}

                </div>
            )
        })
    })

    let bubbleOffset = 0;
    if (selectedTour && selectedTour.performances.length > 0) {
        const startDate = selectedTour.performances[0].asset.startDate;
        const endDate = selectedTour.performances[selectedTour.performances.length - 1].asset.endDate;
        let selectedTourCenter = calcCenterInYears(startDate, endDate);
        const centerPos = calcPosition(selectedTourCenter);


        if (selectedTourCenter.year < year) {
            const yearDiff = year - selectedTourCenter.year;
            bubbleOffset = centerPos - (100 * yearDiff);
        } else if (selectedTourCenter.year > year) {
            const yearDiff = selectedTourCenter.year - year;
            bubbleOffset = centerPos + (100 * yearDiff);
        } else {
            bubbleOffset = centerPos;
        }
    }

    return (
        <div className="tour-wrapper yearly">
            {toursJSX}

            {selectedTour && selectedTour._id === selectedEventId && <div className="tour-info-wrapper" style={{left:`calc(${bubbleOffset}%)`}}>
                <TimelineEventInfo event={selectedTour}
                                showMoreInfo={props.showMoreInfo}
                                showVideo={props.showVideo}
                                showPhotos={props.showPhotos}
                                onClose={props.onClose} />
            </div>}
        </div>
    );
}

export default TourYear
