import React, { Component } from "react";
import store from "./store";
import { resetMetadata } from "./actions/metas";
import bottomLine from "../images/section-page/bottom-line.webp";
import cn from "classnames";
import VerticalCenter from "./components/vertical-center";
import { Link } from "react-router";
import { connect } from 'react-redux';

export class GettingStartedContainer extends Component {
  constructor() {
    super();
    this.mapLinksToPages = this.mapLinksToPages.bind(this);
  }
  componentWillMount() {
    resetMetadata();
  }
  getContent() {
    return store.getState().pages.byId.audioSetup.content;
  }

  mapLinksToPages(pages) {
    return pages.map(page => {
    return (
      <div className="page-link-button">
        <Link to={page.routePath}>
          <div className="button clear">
            <div className="text">{page.headerText}</div>
          </div>
        </Link>
      </div>
    )
    })
  }

  render() {
    let cx = cn("section-page", "audio-setup-page");

    const filteredPages = this.props.pages.filter(p => p.showInGettingStarted).sort((a, b) => a.order - b.order);

    return (
      <VerticalCenter className={cx} ref="bg">
        <div className="content-area-wrapper">
          <span className="content-area-span"/>
          <div className="content-area">
            <div className="background"/>
            <div
              style={{
                position: "absolute",
                top: "20px",
              }}
            >
              <div
                style={{
                  marginBottom: "30px",
                }}
              >
                <img src={bottomLine} loading="lazy"/>
                <h1
                  style={{
                    fontSize: "3.5em",
                    textAlign: "center",
                    margin: "0",
                    fontFamily: "Veneer",
                    letterSpacing: "0.07em",
                    color: "#1A1711DD"
                  }}
                >
                  GETTING STARTED
                </h1>
                <img src={bottomLine} loading="lazy"/>
              </div>
              {this.mapLinksToPages(filteredPages)}
            </div>
          </div>
        </div>
      </VerticalCenter>
    );
  }
}

const mapStateToProps = state => {
  const {pages: {all}} = state;
  return {pages: all}
}

export default connect(mapStateToProps, {})(GettingStartedContainer);
