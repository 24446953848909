import React, {Component}       from 'react'
import _                        from 'lodash'
import SectionPage              from './components/section-page'
import {getUserInfo, sendEmail} from './services/api';
import cn                       from 'classnames'
import contactImg from '../images/section-page/contact.webp'
import { resetMetadata } from './actions/metas'
import {userHasSubscription, hasPatron}             from './user'



class Select extends Component {
    constructor(props){
        super(props)

        this.state = {
            value: props.value,
            popup: false,
            notPermited:[]
        }

        this.clickItem = this.clickItem.bind(this)
        this.clickSelect = this.clickSelect.bind(this)
        this.closePopup = this.closePopup.bind(this)
    }
    clickSelect(e){
        e.preventDefault()
        e.stopPropagation()
        this.setState({popup:!this.state.popup})
    }
    clickItem(e){
        e.preventDefault()
        e.stopPropagation()
        let onlySubscribersOptions=['neil','archivist' ]
        let value = e.target.getAttribute('data-value')
        if(userHasSubscription()){
            this.props.onChange({target: {value}})
            this.setState({popup:false})
        }else{
            if(onlySubscribersOptions.includes(value)){
                let {notPermited} = this.state
                let option= notPermited
                if(!notPermited.includes(value)) option = notPermited.concat(value)
                this.setState({ popup:false, notPermited:option})
                this.props.onChange({target: {value:null}})
                window.subs()
            }else{
                this.props.onChange({target: {value}})
                this.setState({popup:false})
            }
        }
    }
    selectedItemLabel(){
        let item = _.find(this.props.options, ({value})=>value===this.props.value)
        return item.label
    }
    closePopup() {
        this.setState({popup: false})
    }
    render(){
        return (
            <div className="select" tabIndex="0" onBlur={this.closePopup} onClick={this.clickSelect}>
              <div className="current-item">{this.selectedItemLabel()}<div className="arrow" /></div>
              {
                  this.state.popup &&
                  <div className="popup">
                    {
                        this.props.options.map(({label, value}, idx)=>{
                            let cx = cn('option', {selected:value === this.props.value}, {notPermited:this.state.notPermited.includes(value)})
                            return (
                                <div className={cx} onClick={this.clickItem} key={idx}
                                     data-value={value}>{label}<div className="check" />
                                </div>
                            )
                        })
                    }
                  </div>
              }
            </div>
        )
    }
}


const options = [
    {value: null,      label: 'Select a Subject'},
    {value: 'neil',      label: 'Letters for Neil'},
    {value: 'archivist', label:'Question for the Archivist'},
    {value: 'help',      label:'Customer Support'},
    {value: 'billing',   label:'Billing Issue'},
    {value: 'data',      label: 'My Introduction'},
]
const link = <a href="/privacy" target="blank" >Privacy Policy</a>
const helpText = `Do you have a question about something in the Archive?\nSend a note in the form below\nand the archivist will get back to you.`
const archivistText = `Do you have a question or suggestion for the archivist?\nSend it along through the form below.\nPlease note: Your question/comment may be printed in the NYA Times-Contrarian.`
const billingText = `Do you have a billing issue or problem with your NYA subscription?\nSend a note in the form below and we will get back to you shortly.`;
const dataText = `Do you want to exercise your right to access/rectify/restrict/object to/request the erasure, delivery or transfer of your data as outlined in our`
const neil = `Send your letter to Neil here. \n\nHe usually answers in the ‘Letters’ section of the NYA Times - Contrarian.`
const dataText2 = `?\n\nSend us a note and we will figure it out together.`
const successText = `SUCCESS:\nYour message was sent.\nThanks!`
const errorText = `FAILURE:\nYour message failed to send.\nPlease try again.`
const patronText='Welcome to our Patron only contact page.\nYou can use this form to contact us and your message will be marked as high priority.\n\n*Please continue to submit your letters and questions to "Letters for Neil."';

export default class ContactContainer extends Component {
    constructor(props){
        super(props)
        let info    = getUserInfo()
        const email = (info.user_metadata && info.user_metadata.customEmail) || info.email

        const initialValue = options[parseInt(props.location.query.option) || 0].value;

        this.state = {
            submitEnabled:true,
            email: email || '',
            name: info.name || '',
            body: '',
            subject: initialValue,
            state: 'entry',
            option: 0,
            errors:{},
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.setKey   = this.setKey.bind(this);

        if (hasPatron()) {
            const patron_option = {value: 'patron', label: 'Patron Only'};
            options.push(patron_option);
        }
    }

    componentWillMount(){
        resetMetadata()
    }

    setKey(key){
        return e => {
            this.setState({[key]:e.target.value})
        }
    }
    subscriberSubject(subject){
        let onlySubscribersOptions=['neil','archivist' ]
        if(onlySubscribersOptions.includes(subject)){
            subject+='-subscribers'
        }
        return subject
    }
    handleValidation(){
        const {name, email,subject, body} = this.state
        let formIsValid = true;
        let errors={};
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regexp.test(email) || !email){
            errors['email'] = "Email not valid"
            formIsValid = false;
          }else if(subject ==null || !subject){
             errors['subject'] = "Select a valid subject"
             formIsValid = false;
          }else if(!body){
             errors['message'] = "message is empty"
             formIsValid = false;
          }else if(!name){
             errors['name'] = "name is empty"
             formIsValid = false
          }else{
             formIsValid = true;
          }
          this.setState({errors:errors});
          return formIsValid;
    }

    onSubmit(){
        let {name, email, body, subject, state} = this.state
        if (state === 'loading') return;
        if(subject&&userHasSubscription())subject = this.subscriberSubject(subject)
        if(this.handleValidation()){
            this.setState({state:'loading', errors:''})
            sendEmail({
                name, 'from':email, body, subject, to:subject
            }).then(()=>{
                this.refs.body.value = ''
                this.setState({state:'ok', body: ''})
            }).catch(err=>{
                console.error('error sending email: ', err)
                this.setState({state:'error'})
            })
        }
    }

    headerLabel(state, subject) {
        if ( state === 'loading' ) {
            return <span>Sending your message...</span>
        }
        else if ( state === 'error') {
            return <span>{errorText}</span>
        }
        else if ( state === 'ok') {
            return <span>{successText}</span>
        }
        else if (subject === 'archivist') {
            return <span>{archivistText}</span>
        } else if (subject === 'data') {
            return <span>{dataText} {link} {dataText2}</span>
        } else if ( subject === 'billing') {
            return <span>{billingText}</span>
        } else if ( subject === 'help') {
            return <span>{helpText}</span>
        }else if ( subject === 'neil') {
            return <span>{neil}</span>
        }else if (subject === 'patron') {
            return <span>{patronText}</span>
        }
    }
    render(){
        let { name, email, body, subject, state} = this.state

        let messageClass = cn('message', state)

        return (
            <SectionPage
                className="contact-page"
                headerImageUrl={contactImg}
                showUserMenu={true}>
                <div className="contact-form">
                    <div className={messageClass}>{this.headerLabel(state, subject)}</div>
                    <div className="form-wrapper">
                        <div>
                            <div className="label">Name</div>
                            <input type="text" ref="name" className="name" value={name} onChange={this.setKey('name')} />
                            <span className="contact-error">{this.state.errors.name}</span>
                        </div>
                        <div>
                            <div className="label">Email</div>
                            <input type="text" ref="email" className="email" value={email} onChange={this.setKey('email')} />
                            <span className="contact-error">{this.state.errors.email}</span>
                        </div>
                        <div>
                            <div className="label">Subject</div>
                            <Select options={options} value={subject} onChange={this.setKey('subject')} />
                            <span className="contact-error">{this.state.errors.subject}</span>
                        </div>
                        <div>
                            <div className="label">Message</div>
                            <textarea ref="body" className="body" value={body} onChange={this.setKey('body')} />
                            <span className="contact-error message">{this.state.errors.message}</span>
                        </div>
                        <div className="submit-wrapper">
                            <div className="submit" disabled={!(name && email && body && subject && state !== 'loading')} onClick={this.onSubmit}>
                                <div className="submit-label">{state === 'loading' ? 'SENDING...' : 'SUBMIT'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionPage>
        )
    }
}
